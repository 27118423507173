jQuery(function($){

  //===============================================
  /* CMS向けにブロック要素に背景画像を設定 */
  //===============================================
  var d = document.getElementsByClassName("js-background");
  for(var i=0; i<d.length; i++){
    var f=d[i].getAttribute("data-src");
    d[i].style.backgroundImage="url('"+f+"')";
  };

  //===============================================
  /* pagetopボタン */
  //===============================================
  $('#btn_pagetop').click(function() {
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    var speed = 500; // スクロールの速度（ミリ秒）
    $('body,html').animate({scrollTop:position}, speed, 'swing'); // スクロール方式
    return false;
  });

  //===============================================
  /* トップに戻るボタンなどの制御 */
  //===============================================
  var topBtn = $(".js-btn_pagetop");

  //スクロールしてページトップから100に達したらボタンを表示
  $(window).on('load scroll', function(){
    if($(this).scrollTop() > 100) {
      topBtn.addClass('active');
    }else{
      topBtn.removeClass('active');
    }
  });

  //フッターの手前でボタンを止める
  $(window).on('load scroll', function(){
    var height = $(document).height(), //ドキュメントの高さ 
        position = window.innerHeight + $(window).scrollTop(), //ページトップから現在地までの高さ
        footer = $('#footer').height(); //フッターの高さ
    if ( height - position  < footer ){ 
      topBtn.addClass('absolute');
    } else { 
      topBtn.removeClass('absolute');
    }
  });


  //「#」のみのリンクには反応させない
  $('a[href^="#"]').click(function() {
    return false;
  });

});

//===============================================
/* スクロールの高さ取得 */
//===============================================
function getScrollHeight(tgt, height) {
  var minusheight = window.innerHeight - height;
  $(tgt).css('height', minusheight);
}

//===============================================
/* スクロール変更 */
//===============================================
function psUpdate(obj) {
    getScrollHeight('#content', minusHeight2);
    obj.update();
}

function is_smartphone(){
  return ($(window).width() < 768);
}

//===============================================
/* ページ内リンクスムーススクロール */
//===============================================
const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
for (let i = 0; i < smoothScrollTrigger.length; i++){
  smoothScrollTrigger[i].addEventListener('click', (e) => {
    e.preventDefault();
    let href = smoothScrollTrigger[i].getAttribute('href');
    let targetElement = document.getElementById(href.replace('#', ''));
    const rect = targetElement.getBoundingClientRect().top;
    const offset = window.pageYOffset;
    const gap = $('#header').outerHeight();
    const target = rect + offset - gap + 1;
    window.scrollTo({
      top: target,
      behavior: 'smooth',
    });
  });
}